<template>
  <span>
    <div
      v-if="loading"
      class="card card--table"
    >
      <div
        v-if="loading"
        class="loading position-relative"
      >
        <div class="effect-1 effects" />
        <div class="effect-2 effects" />
        <div class="effect-3 effects" />
      </div>
    </div>
    <CompleteTable
      v-else
      :sorted-field="sortedField"
      :data="statsTable"
      :sort-mapping="sortMapping"
      :title="'result'"
      :total="statsTableTotal"
      :fetch-path="'scientificStats/fetch'"
      :filter-path="'scientificStats/filter'"
    >
      <template v-slot="{field}">
        <td
          class="text-end"
        >
          <a
            v-if="field.userId"
            class="btn btn-icon rounded-pill btn-flat-secondary"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Go to scientific production"
            @click="goToScientificProductionReport(field.userId)"
          ><i data-feather="eye" /></a>
        </td>
      </template>
      <template #customfilters>
        <div class="mb-1">
          <label
            for="nameseniorcall"
            class="form-label"
          >Researcher</label>
          <v-select
            label="name"
            :placeholder="'Type 3 or more characters to search...'"
            :options="users"
            :get-option-key="option => option.id"
            @input="saveFilter($event ? $event.name : null, 'name')"
            @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
          />
          <hr>
        </div>
        <div class="mb-1">

          <label
            for=""
            class="form-label"
          >Active</label>
          <select
            id=""
            name=""
            class="form-select"
            @input="saveFilter($event.target.value != '' ? {value: $event.target.value} : null , 'active')"
          >
            <option value="">
              All
            </option>
            <option value="true">
              Yes
            </option>
            <option value="false">
              No
            </option>
          </select>
        </div>
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >Host</label>
          <v-select
            label="name"
            multiple
            :options="hosts"
            :get-option-key="option => option.id"
            @input="saveFilter($event, 'hosts')"
            @search="onSearch({ name: $event }, 'institutions/filter')"
          />
        </div>
        <div class="mb-1">
          <label
            for="areaseniorcall"
            class="form-label"
          >Area</label>
          <v-select
            label="name"
            multiple
            :options="areas"
            :get-option-key="option => option.id"
            @input="saveFilter($event, 'areas')"
          >
            <option
              value=""
              selected
            >
              All areas
            </option>
          </v-select>
        </div>
        <hr>
        <h4 class="mb-2">Filter content</h4>
        <div class="mb-3">
          <div class="form-check form-check-inline">
            <input
              id="inlineRadio1"
              class="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              value="with"
              checked=""
              @click="saveFilter('with', 'withRelations')"
            >
            <label
              class="form-check-label"
              for="inlineRadio1"
            >With</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              id="inlineRadio2"
              class="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              value="without"
              @click="saveFilter('without', 'withRelations')"
            >
            <label
              class="form-check-label"
              for="inlineRadio2"
            >Without</label>
          </div>
        </div>

        <div class="mb-1">
          <div class="form-check mb-2">
            <input
              id="inlineCheckbox1Publications"
              class="form-check-input"
              type="checkbox"
              value="checked"
              checked=""
              @click="checkRelations($event, 'publications')"
            >
            <label
              class="form-check-label"
              for="inlineCheckbox1Publications"
            >Publications</label>
          </div>
          <div class="form-check mb-2">
            <input
              id="inlineCheckbox1Grants"
              class="form-check-input"
              type="checkbox"
              value="checked"
              checked=""
              @click="checkRelations($event, 'grants')"
            >
            <label
              class="form-check-label"
              for="inlineCheckbox1Grants"
            >Grants</label>
          </div>
          <div class="form-check mb-2">
            <input
              id="inlineCheckbox1Conferences"
              class="form-check-input"
              type="checkbox"
              value="checked"
              checked=""
              @click="checkRelations($event, 'conferences')"
            >
            <label
              class="form-check-label"
              for="inlineCheckbox1Conferences"
            >Conferences</label>
          </div>
          <div class="form-check mb-2">
            <input
              id="inlineCheckbox1PhD"
              class="form-check-input"
              type="checkbox"
              value="checked"
              checked=""
              @click="checkRelations($event, 'phds')"
            >
            <label
              class="form-check-label"
              for="inlineCheckbox1PhD"
            >PhD Thesis</label>
          </div>
          <div class="form-check mb-2">
            <input
              id="inlineCheckbox1PhD"
              class="form-check-input"
              type="checkbox"
              value="checked"
              checked=""
              @click="checkRelations($event, 'courses')"
            >
            <label
              class="form-check-label"
              for="inlineCheckbox1PhD"
            >Courses</label>
          </div>
          <div class="form-check mb-2">
            <input
              id="inlineCheckbox1Managerials"
              class="form-check-input"
              type="checkbox"
              value="checked"
              checked=""
              @click="checkRelations($event, 'managerial')"
            >
            <label
              class="form-check-label"
              for="inlineCheckbox1Managerials"
            >Managerials</label>
          </div>
          <div class="form-check mb-2">
            <input
              id="inlineCheckbox1Disseminations"
              class="form-check-input"
              type="checkbox"
              value="checked"
              checked=""
              @click="checkRelations($event, 'disseminations')"
            >
            <label
              class="form-check-label"
              for="inlineCheckbox1Disseminations"
            >Disseminations</label>
          </div>
          <div class="form-check mb-2">
            <input
              id="inlineCheckbox1Disseminations"
              class="form-check-input"
              type="checkbox"
              value="checked"
              checked=""
              @click="checkRelations($event, 'stays_research')"
            >
            <label
              class="form-check-label"
              for="inlineCheckbox1Disseminations"
            >Stays of research</label>
          </div>
          <div class="form-check mb-2">
            <input
              id="inlineCheckbox1Patents"
              class="form-check-input"
              type="checkbox"
              value="checked"
              checked=""
              @click="checkRelations($event, 'patents')"
            >
            <label
              class="form-check-label"
              for="inlineCheckbox1Patents"
            >Technology transfer</label>
          </div>
          <div class="form-check mb-2">
            <input
              id="inlineCheckbox1Patents"
              class="form-check-input"
              type="checkbox"
              value="checked"
              checked=""
              @click="checkRelations($event, 'sabbaticals')"
            >
            <label
              class="form-check-label"
              for="inlineCheckbox1Patents"
            >Sabbaticals</label>
          </div>
        </div>
      </template>
    </CompleteTable>
  </span>
</template>

<script>
import { mapGetters } from 'vuex'
import CompleteTable from '@/views/back/partials/components/CompleteTable.vue'
import vSelect from 'vue-select'

export default {
  components: {
    CompleteTable,
    vSelect,
  },
  props: {
    yearBetween: { type: Object, required: true, default: () => {} },
  },
  data() {
    return {
      loading: true,
      sortedField: 'Name',
      relationsSelected: [
        'publications',
        'grants',
        'conferences',
        'phds',
        'courses',
        'managerial',
        'disseminations',
        'patents',
        'stays_research',
        'sabbaticals',
      ],
      defaultFields: [
        {
          name: 'Name',
          checked: true,
          order: 1,
        },
        {
          name: 'Year',
          checked: true,
          order: 2,
        },
        {
          name: 'Publications',
          checked: true,
          order: 4,
        },
        {
          name: 'Grants',
          checked: true,
          order: 5,
        },
        {
          name: 'Conferences',
          checked: true,
          order: 6,
        },
        {
          name: 'Phd thesis',
          checked: true,
          order: 7,
        },
        {
          name: 'Courses',
          checked: true,
          order: 8,
        },
        {
          name: 'Managerials',
          checked: true,
          order: 9,
        },
        {
          name: 'Disseminations',
          checked: true,
          order: 10,
        },
        {
          name: 'Stays of research',
          checked: true,
          order: 11,
        },
        {
          name: 'Technology transfer',
          checked: true,
          order: 12,
        },
        {
          name: 'Sabbaticals',
          checked: true,
          order: 13,
        },
      ],
      sortMapping: {
        Name: 'user',
        Year: 'year',
        Publications: 'publications.num',
        Grants: 'grants.num',
        Conferences: 'conferences.num',
        'Phd thesis': 'phds.num',
        Courses: 'courses.num',
        Managerials: 'managerial.num',
        Disseminations: 'disseminations.num',
        'Stays of research': 'stays-research.num',
        'Technology transfer': 'patents.num',
        Sabbaticals: 'sabbaticals.num',
      },
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      statsTable: 'scientificStats/items',
      statsTableTotal: 'scientificStats/itemsTotal',
      hosts: 'institutions/institutions',
      areas: 'areas/areas',
      users: 'users/fakeUsers',
      filters: 'filters/filters',
    }),
  },
  watch: {
    yearBetween() {
      this.reloadData()
    },
  },
  async mounted() {
    this.loading = true
    this.saveFilter('with', 'withRelations')
    this.relationsSelected.forEach(relation => {
      this.saveFilter(relation, relation)
    })
    await this.$store.dispatch('modals/fetchUserFields', 'scientific-stats-global')
    await this.$store.dispatch('areas/filter', this.$store.getters['filters/filters'])
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'scientific-stats-global',
      })
    }
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
    this.reloadData()
  },
  methods: {
    async reloadData() {
      this.loading = true
      if (Object.values(this.yearBetween).length > 0) {
        this.saveFilter(this.yearBetween, 'yearBetween')
      } else {
        this.saveFilter(null, 'yearBetween')
      }
      await this.$store.dispatch('scientificStats/filter', this.filters)
      this.loading = false
    },
    async onSearch(search, store) {
      // eslint-disable-next-line no-param-reassign
      search.active = true
      if (search.name !== '' && search.name.length > 2) {
        await this.$store.dispatch(store, search)
      }
    },
    saveFilter(value, field) {
      this.$store.dispatch('filters/saveFilters', {
        value,
        field,
      })
    },
    checkRelations($event, relation) {
      if ($event.target.checked && typeof this.relationsSelected.relation === 'undefined') {
        this.saveFilter(relation, relation)
      } else if (!$event.target.checked) {
        this.saveFilter(null, relation)
      }
    },
    goToScientificProductionReport(userId) {
      const queryParams = this.checkQueryParams(this.yearBetween, userId)
      const url = this.$router.resolve({ name: 'researcher.scientific-production-report.index', query: queryParams }).href
      window.open(url, '_blank')
    },
    checkQueryParams(dateFilters, userId) {
      let queryParams = { prefilterUser: userId }
      if (dateFilters && Object.keys(dateFilters).length > 0) {
        if (dateFilters.from && dateFilters.to) {
          queryParams = { ...queryParams, from: dateFilters.from, to: dateFilters.to }
        } else if (dateFilters.from) {
          queryParams = { ...queryParams, from: dateFilters.from }
        } else if (dateFilters.to) {
          queryParams = { ...queryParams, to: dateFilters.to }
        } else if (dateFilters.calculateBetween) {
          queryParams = { ...queryParams, from: dateFilters.calculateBetween - 5, to: dateFilters.calculateBetween - 1 }
        }
      }
      return queryParams
    },
  },
}
</script>

<style>

</style>
